<template>
  <!--begin::Mixed Widget 1-->
  <div class="card card-custom bg-gray-100 card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5" style="background: #0f7dcc">
      <h3 class="card-title font-weight-bolder text-white">
        Statistik Bulan Ini
      </h3>
      <!-- <div class="card-toolbar">
        <Dropdown1></Dropdown1>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body p-0 position-relative overflow-hidden">
      <!--begin::Chart-->
      <!-- <apexchart
        class="card-rounded-bottom bg-danger pb-5"
        :options="chartOptions"
        :series="series"
        type="area"
        v-if="chartLoad == true"
      ></apexchart> -->
      <!--end::Chart-->
      <!--begin::Stats-->
      <div class="card-spacer mt-n25 mt-5">
        <!--begin::Row-->
        <div class="row m-0">
          <!-- <div class="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7"> -->
          <div class="col bg-light-warning col-12 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2 d-flex justify-content-center">
               <!-- <inline-svg src="media/svg/icons/Media/Equalizer.svg" /> 
              <i class="fas fa-receipt text-warning fa-2x mt-1 mr-1"></i> -->
              <i class="fas fa-receipt text-warning fa-2x mt-1 mr-1"></i>
              <span class="text-warning font-weight-bold font-size-h1 ">
                <!-- invoice rumah sakit -->
                {{data.income}}
              </span>
            </span>
            <span class="text-warning font-weight-bold font-size-h6 mt-3 d-flex justify-content-center">
              Invoice Rumah Sakit
            </span>
            
          </div>
          <!-- <div class="col bg-light-primary px-6 py-8 rounded-xl mb-7"> -->
          <div class="col bg-light-primary col-12 mt-4 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2 d-flex justify-content-center">
              <!-- <inline-svg src="media/svg/icons/Communication/Add-user.svg" /> -->
              <i class="fas fa-user-injured text-primary fa-2x mt-1 mr-1"></i>
              <span class="text-primary font-weight-bold font-size-h1 ">
                <!-- latest patient rumah sakit -->
                {{data.patient}}
              </span>
            </span>
            <span  class="text-primary font-weight-bold font-size-h6 mt-2 d-flex justify-content-center">
              Pasien Baru
            </span>
          </div>
        </div>
        <!--end::Row-->
        <!--begin::Row-->
        <div class="row m-0">
          <!-- <div class="col bg-light-danger px-6 py-8 rounded-xl mr-7"> -->
          <div class="col-12 bg-light-danger mt-4 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2 d-flex justify-content-center">
              <!-- <inline-svg src="media/svg/icons/Design/Layers.svg" /> -->
              
              <i class="fas fa-pills text-danger fa-2x mt-1 mr-1"></i>
              <span class="text-danger font-weight-bold font-size-h1 ">
                <!-- latest patient rumah sakit -->
                {{data.medicineOrder}}
              </span>
            </span>
            <span  class="text-danger font-weight-bold font-size-h6 mt-2 d-flex justify-content-center">
              Transaksi Apotik
            </span>
          </div>
          <!-- <div class="col bg-light-success px-6 py-8 rounded-xl"> -->
          <div class="col-12 bg-light-success mt-4 rounded-xl">
            <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2 d-flex justify-content-center">
              <!-- <inline-svg src="media/svg/icons/Communication/Urgent-mail.svg" />
              <i class="fas fa-calendar-plus text-success fa-2x mt-1 mr-1"></i> -->
              <i class="fas fa-calendar-plus text-success fa-2x mt-1 mr-1"></i>
              <span class="text-success font-weight-bold font-size-h1 ">
                <!-- latest patient rumah sakit -->
                {{data.appointment}}
              </span>
            </span>
            <span  class="text-success font-weight-bold font-size-h6 mt-2 d-flex justify-content-center">
              Perjanjian
            </span>
          </div>
        </div>
        <!--end::Row-->
      </div>
      <!--end::Stats-->
      <!--end::Body-->
    </div>
    <!--end::Mixed Widget 1-->
  </div>
</template>

<script>
import Dropdown1 from "@/view/content/dropdown/Dropdown1.vue";
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from 'moment'

export default {
  name: "widget-1",
  components: {
    Dropdown1
  },
  data() {
    return {
      strokeColor: "#D13647",
      chartOptions: {},
      series: [
        {
          name: "Jumlah ",
          data: []
        }
      ],
      data:{
        income:"",
        patient:"",
        medicineOrder:"",
        appointment:""

      },
      chartLoad:false,
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted() {
    this.getData()
  
  },
  methods:{
    async getData(){
      
      let data = await module.get('dashboard-monthly')
      
      this.data.income = data.payment
      this.data.patient = data.patient
      this.data.medicineOrder = data.pharmacy
      this.data.appointment = data.appointment
  
    }      
  },
};
</script>

<style scoped>
.bg-base{
  background-color: #245590 !important;
}

</style>
