<template>
  <v-app>
    <div>
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xl-4" v-if="dashboardAccess == true">
          <MixedWidget1></MixedWidget1>
        </div>
        <div class="col-xl-4" v-if="dashboardAccess == true">
          <ListWidget9></ListWidget9>
        </div>
        <!-- <div class="col-xxl-4">
        <AppointmentTomorrow></AppointmentTomorrow>
      </div> -->

        <div class="col-xl-4" v-if="dashboardAccess == true">
          <StatsWidget7></StatsWidget7>
          <StatsWidget12></StatsWidget12>
        </div>

        <div
          class="col-xl-4 order-1 order-xxl-1"
          v-if="dashboardAccess == true"
        >
          <ListWidget1></ListWidget1>
        </div>
        <div
          class="col-xl-8 order-2 order-xxl-2"
          v-if="dashboardAccess == true"
        >
          <AdvancedTableWidget2></AdvancedTableWidget2>
        </div>

        <!-- if dashboard accesss false -->
        <div
          class="col-xl-6 order-1 order-xxl-1"
          v-if="dashboardAccess == false"
        >
          <ListWidget1></ListWidget1>
        </div>
        <div class="col-xl-6" v-if="dashboardAccess == false">
          <ListWidget9></ListWidget9>
        </div>
        <!-- end false -->

        <!-- <div class="col-xl-12 order-3 order-xxl-3">
          <Calendar></Calendar>
        </div> -->

        <!-- <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <StatsWidget13></StatsWidget13>
      </div>
      <div class="col-xxl-8 order-1 order-xxl-2">
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div> -->
      </div>
      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import AppointmentTomorrow from "@/view/content/widgets/list/AppointmentNextDay.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
import Calendar from "@/view/pages/appointments/Calendar.vue";

export default {
  data() {
    return {
      dashboardAccess: false,
    };
  },
  name: "dashboard",
  components: {
    AdvancedTableWidget2,
    AdvancedTableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13,
    AppointmentTomorrow,
    Calendar,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.setActiveMenu();
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        ;

        if (access_right[a] == "1001") {
          this.dashboardAccess = true;
        }
      }
    },
  },
};
</script>
<style>
.text-primary {
  color: #007bff !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-success {
  color: #28a745 !important;
}
.text-info {
  color: #17a2b8 !important;
}
</style>
