<template>
  <!--begin::Advance Table Widget 2-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Invoice Hari Ini
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{dataLength}} Invoice Baru Hari Ini
        </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive overflow-auto">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>
              <th class="p-0" style="min-width: 200px"></th>
              <th class="p-0" style="min-width: 130px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 120px"></th>
              <!-- <th class="p-0" style="min-width: 150px"></th> -->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in items">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                     
                      <img
                        src="/images/doctor.jpeg"
                        class="h-100 align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.doctor_name ? item.doctor_name : "-" }}
                  </a>
                  <div>
                    <span class="font-weight-bolder">Pasien :</span>
                    <a class="text-info font-weight-bold text-hover-primary">
                      {{ item.patient_name ? item.patient_name : "-" }}
                    </a>
                  </div>
                </td>
                <td class="text-right">
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ "Rp " + parseInt(item.final_amount).toLocaleString('id-ID') }}
                  </span>
                  <!-- <span class="text-muted font-weight-bold">Paid</span> -->
                </td>
                <td class="text-right">
                   <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-info`"
                  >
                     {{ item.payment_method_name }}
                  </span>
                </td>
                <td class="text-center">
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-warning`"
                    v-if="item.feature == 'Apotik'"
                  >
                    {{ item.feature }}
                  </span>
                  <span
                    class="label label-lg label-inline"
                    v-bind:class="`label-light-primary`"
                    v-if="item.feature == 'Rumah Sakit'"
                  >
                    {{ item.feature }}
                  </span>
                </td>
                <!-- <td class="text-center">
                  <a class="btn btn-icon btn-light btn-sm mx-3" @click="$route.push()">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      begin::Svg Icon
                      <inline-svg
                        src="/media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      end::Svg Icon
                    </span>
                  </a>
                </td> -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
       <b-pagination

        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @page-click="pageOnClick"
        class="mt-4"
        v-if="showPaging"
      ></b-pagination>
    </div>
    <!--end::Body-->
    <!--end::Advance Table Widget 2-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "widget-2",
  data() {
    return {
      items: [],
      dataLength:0,
      // Pagination
      perPage: 10,
      currentPage: 1,
      totalRows: 0,
      showPaging:false,
    };
  },
  components: {},
  methods: {
    async getData(){
      let response = await module.get('payments-pharmacy-long-dashboard-income')
      let resPaginate = await module.paginate('payments-pharmacy-dashboard-income', `?page=${this.currentPage}&page_size=${this.perPage}`)
      
      this.items = resPaginate.data
      this.totalRows = resPaginate.meta.pagination.total
      this.dataLength = response.length
      if(this.totalRows > 10 ){
        this.showPaging = true
      }
    },
     async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.getData();
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  mounted(){
    this.getData()
  }

};
</script>
